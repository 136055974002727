import React from "react";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        <header>
          <div className="page_width rel">
            <nav>
              <a href="/" id="logo" />
              <span id="menu_btn" />
              <ul id="menu">
                <li>
                  <a href="/">Hlavná stránka</a>
                </li>
                <li>
                  <a href="/lieky/">Lieky</a>
                </li>
                <li>
                  <a href="/radca/">Radca</a>
                </li>
                <li>
                  <a href="/upravte_liek/">Test</a>
                </li>
                <li>
                  <a href="/kontakt/">Kontakt</a>
                </li>
                <li>
                  <a href="/otazky_a_odpovede/">Otázky a odpovede</a>
                </li>
                <li>
                  <a href="/kde_kupit/">KDE KÚPIŤ </a>
                </li>
              </ul>
            </nav>
          </div>
        </header>
        <div id="page" className="page_width">
          <div id="breadcrumbs">
            <a href="/">Hlavná stránka</a>
            <a href="/televizna_reklama/" className="sel">
              {" "}
              &gt; Televízna reklama
            </a>
          </div>
          <div id="page_content">
            <div className="col2">
              <div className="wysiwyg">
                <video controls width="100%">
                  <source
                    src="/kcfinder/uploads/files/film/Olynth%20HA_SK_2018.mp4"
                    type="video/mp4"
                  />
                </video>
              </div>
            </div>
            <div className="col1">
              <div className="related">
                <p className="name">Zistiť viac</p>
                <div className="line">
                  <a href="/radca/prechladnutie/domace_sposoby_boja_s_prechladnutim/">
                    <div className="c1">
                      <span className="photo" style={{ backgroundImage: "url(/web/kcfinder/uploads/files/artykuly/poznaj_rodzaje_kataru.png)" }} />
                    </div>
                    <div className="c2">
                      Domáce spôsoby boja s prechladnutím{" "}
                    </div>
                  </a>
                </div>
                <div className="line">
                  <a href="/radca/nadcha/spoznajte_typy_nadchy/">
                    <div className="c1">
                      <span className="photo" style={{ backgroundImage: "url(/web/kcfinder/uploads/files/artykuly/domowe_sposoby_na_walke_przeziębieniem.png)" }} />
                    </div>
                    <div className="c2">Spoznajte typy nádchy </div>
                  </a>
                </div>
              </div>
              <a href="/radca/" className="link_box link">
                <img src="/web/kcfinder/uploads/files/box/sk/poradnik.png" />
              </a>
              <a href="/upravte_liek/" className="link_box link">
                <img src="/web/kcfinder/uploads/files/box/sk/test.png" />
              </a>
              <a href="/televizna_reklama/" className="quiz_btn" />
            </div>
            <div className="clear" />
          </div>{" "}
        </div>
        <Footer></Footer>{" "}

      </div>
    );
  }
}

export default Page;
